import React from 'react';
import useLanguage from '../components/Hooks/useLanguage';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';
import Instagram from '../components/Layout/Instagram/Instagram';
import ServicesInnerMain from '../components/Pages/ServicesInner/ServicesInnerMain/ServicesInnerMain';
import ServiceInnerVideo from '../components/Pages/ServicesInner/ServiceInnerVideo/ServiceInnerVideo';
import TrainingDefaultAnswers from '../components/Pages/TrainingDefault/TrainingDefaultAnswers/TrainingDefaultAnswers';
import dataServicesInner from '../db/dataServicesInner';
import ServicesInnerType from '../components/Pages/ServicesInner/ServicesInnerType/ServicesInnerType';
import ServicesInnerGallery from '../components/Pages/ServicesInner/ServicesInnerGallery/ServicesInnerGallery';
import TrainingDefaultBook from '../components/Pages/TrainingDefault/TrainingDefaultBook/TrainingDefaultBook';
import ServicesInnerInfo from '../components/Pages/ServicesInner/ServicesInnerInfo/ServicesInnerInfo';
import isEmpty from 'lodash/isEmpty';
import isNil  from 'lodash/isNil';

const ServicesInner = ({data}) => {
  const langToggle = useLanguage;
  const serviceInner = dataServicesInner;
  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
  ];

  return (
    <Layout whitwHeader={'active'}>
      <Seo  
        title={langToggle(data?.seo_title_ua, data?.seo_title_ru, data?.seo_title_en)} 
        description={langToggle(data?.seo_description_ua, data?.seo_description_ru, data?.seo_description_en)}  
      />
      <ServicesInnerMain
        mainData={data.strapiServices}
        crumbsNav={crumbsNav}
        crumbsCategory={data?.strapiServices?.services_category}
        dataMain={data?.strapiServices}
      />
     {isEmpty(data?.strapiServices?.Price) ? '' : <ServicesInnerType dataType={serviceInner} dataPrice={data?.strapiServices.Price}/>}
     {isEmpty(data?.strapiServices?.gallery) ? '' : <ServicesInnerGallery dataGallery={data?.strapiServices}/>}
      <ServicesInnerInfo 
        dataInfo={serviceInner?.procedures_info} 
        descInfo={data?.strapiServices?.Description} 
        procedure={data?.strapiServices?.procedure}
      />
     {isNil(data?.strapiServices?.image_cover) ? '' : <ServiceInnerVideo dataVideo={data?.strapiServices} dataPartners={data?.strapiServices} />}
      {isEmpty(data?.strapiServices?.Faq) ? '' : <TrainingDefaultAnswers dataAnswers={data?.strapiServices} />}
      <TrainingDefaultBook
        title={{
          title_ua: 'Записатись на консультацію',
          title_ru: 'Записаться на консультацию',
          title_en: 'Book a consultation',
        }}
      />
      <Instagram />
    </Layout>
  );
};

export default ServicesInner;

export const query = graphql`
  query ServicesTemplateQuery($language: String!, $id: String!) {
    strapiServices(id: { eq: $id }) {
      seo_title_ua
      seo_title_ru
      seo_title_en
      seo_description_ua
      seo_description_ru
      seo_description_en
      title_ua
      title_ru
      title_en
      sub_title_ua
      sub_title_ru
      sub_title_en
      slug
      image {
        localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
        }
        Price {
          title_ua
          title_ru
          title_en
          price_en
          price
        }
        gallery {
          image {
            localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
            }
        }
        Description {
          id
          title_ua
          title_ru
          title_en
          description_ua
          description_ru
          description_en
        }
        procedure {
         id
          title_ua
          title_ru
          title_en
          description_ua
          description_ru
          description_en
        }
        image_cover {
          localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
            }
            video_link
        Faq {
          id
          title_ua
          title_ru
          title_en
          content_ua
          content_ru
          content_en
        }
        services_category {
          title_ua
          title_ru
          title_en
          slug
        }
    }


    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
