import React from 'react';
import ReactMarkdown from 'react-markdown';
import useLanguage from '../../../../Hooks/useLanguage';

const ItemContent = ({ procedure }) => {
  const langToggle = useLanguage;
  return (
    <div className={'info-item--preview'}>
        <h2 className="section-description">
          {langToggle('Як це працює', 'Как проходит процедура', 'About the procedure')}
         </h2>
      <div className="marck-down--component">
       {(procedure || []).map((desc, idx) => { 
          return (
            <div key={idx}>
              <h3> {langToggle(desc?.title_ua, desc?.title_ru, desc?.title_en)}</h3>
              <ReactMarkdown source={langToggle(desc?.description_ua, desc?.description_ru, desc?.description_en)} /> 
            </div>   
        )})}
      </div>
    </div>
  );
};

export default ItemContent;
