const dataServicesInner = {
  main_tittle: {
    title_ua: 'Ботулінотерапія',
    title_ru: 'Ботулинотерапия',
    title_en: 'Botulinum Therapy',
    description_ua: 'Диспорт',
    description_ru: 'Диспорт',
    description_en: 'Dysport',
  },
  services_title: {
    title_ua: 'Прайс Лист',
    title_ru: 'Прайс Лист',
    title_en: 'Price List',
  },
  services_list: [
    {
      id: 1,
      title_ua: 'Міжбрівна зона',
      title_ru: 'Межбровная зона',
      title_en: 'Eyebrow area',
      price_ua: '2900 - 4300 грн',
      price_ru: '2900 - 4300 грн',
      price_en: '2900 - 4300 uah',
    },
    {
      id: 2,
      title_ua: 'Зона чола',
      title_ru: 'Зона лба',
      title_en: 'Forehead area',
      price_ua: '2800 грн',
      price_ru: '2800 грн',
      price_en: '2800 uah',
    },
    {
      id: 3,
      title_ua: 'Періорбітальна зона',
      title_ru: 'Периорбитальная зона',
      title_en: 'Periorbital zone',
      price_ua: '2800 грн',
      price_ru: '2800 грн',
      price_en: '2800 uah',
    },
    {
      id: 4,
      title_ua: 'Нижня частина обличчя',
      title_ru: 'Нижняя часть лица',
      title_en: 'Lower face',
      price_ua: '2800 - 5700 грн',
      price_ru: '2800 - 5700 грн',
      price_en: '2800 - 5700 uah',
    },
    {
      id: 5,
      title_ua: 'Full Face',
      title_ru: 'Full Face',
      title_en: 'Full Face',
      price_ua: '14250 грн',
      price_ru: '14250 грн',
      price_en: '14250 uah',
    },
  ],
  opening_hours: {
    title_ua: 'Пн - СБ / 09:00 - 18:00',
    title_ru: 'Пн - СБ / 09:00 - 18:00',
    title_en: 'Mon - Sat / 09:00 - 18:00',
  },
  procedures_info: [
    {
      id: 'pr01',
      title_ua: 'Про процедуру',
      title_ru: 'О процедуре',
      title_en: 'About the procedure',
    },
    {
      id: 'pr02',
      title_ua: 'Як проходить процедура',
      title_ru: 'Как проходит процедура',
      title_en: 'How is the procedure',
    },
  ],
  // about_procedure: {
  //   title_ua: 'Про процедуру',
  //   title_ru: 'О процедуре',
  //   title_en: 'About the procedure',
  // },
  // step_procedure: {
  //   title_ua: 'Як проходить процедура',
  //   title_ru: 'Как проходит процедура',
  //   title_en: 'How is the procedure',
  // },
  questions: [
    {
      id: 'q01',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
    {
      id: 'q02',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
    {
      id: 'q03',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
    {
      id: 'q04',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
    {
      id: 'q05',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
  ],
};

export default dataServicesInner;
