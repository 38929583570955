import React from 'react';
import VideoModal from '../../../UI/VideoModal/VideoModal';
import VideoPlayer from '../../../UI/VideoPlayer/VideoPlayer';

const ServiceInnerVideo = ({dataVideo, dataPartners}) => {
  const [open, setOpen] = React.useState(false);
  const [play, setPlay] = React.useState(false);

  function openModal() {
    setOpen(!open);
    setPlay(true);
  }

  function closeModal(e) {
    e.preventDefault();
    setOpen(false);
    setPlay(false);
  }

  return (
    <>
    {dataPartners.video_link === null ? '' :
     <section className="service-inner--video">
      <div className="container-fluid">
        {<VideoPlayer dataPartners={dataPartners} openModal={openModal} />}
      </div>
    </section>}
      <VideoModal
         closeModal={closeModal}
         play={play}
         open={open}
         video={dataPartners.video_link}
      />
    </>
  );
};

export default ServiceInnerVideo;
