import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../Hooks/useLanguage';
import {fromCategorySlugToUrl} from '../../../../utils/utils'
import {fromServicestSlugToUrl} from '../../../../utils/utils'

const ServicesInnerMain = ({ mainData, crumbsNav, dataMain, crumbsCategory }) => {
  const langToggle = useLanguage;
  const image = getImage(mainData?.image?.localFile);

  return (
    <>
      <section id="scroller-top" className="service-inner--main scroller-top">
        <div className="mainScreen-variable__bg">
        <GatsbyImage 
          image={image} 
          className="mainScreen-second__image" 
          alt={langToggle(
            dataMain?.title_ua,
            dataMain?.title_ru,
            dataMain?.title_en
          )}
        />
        </div>
        <div className="mainScreen-variable__center">
          <h1 className="h1">
            {langToggle(
              dataMain?.title_ua,
              dataMain?.title_ru,
              dataMain?.title_en
            )}
          </h1>
          <span className="screen-description">
            {langToggle(
              dataMain?.description_ua,
              dataMain?.description_ru,
              dataMain?.description_en
            )}
          </span>
        </div>
      </section>
      <div className="mainScreen-variable__bottom">
        <ul className="crumbsNav">
          {(crumbsNav || []).map((item, index) => {
            return (
              <li className="crumbsNav-item" key={index}>
                <Link className="crumbsNav-item__link" to={item.to}>
                  {langToggle(item?.title_ua, item?.title_ru, item?.title_en)}
                </Link>
              </li>
            );
          })}
          <li className={crumbsCategory ? "crumbsNav-item" : null}>
                <Link className="crumbsNav-item__link" to={mainData ? fromServicestSlugToUrl(crumbsCategory?.slug) : null}>
                  {crumbsCategory ? langToggle(crumbsCategory?.title_ua, crumbsCategory?.title_ru, crumbsCategory?.title_en) : null}
                </Link>
          </li>
          <li className={mainData ? "crumbsNav-item" : null}>
                <Link className="crumbsNav-item__link" to={mainData ? fromCategorySlugToUrl(mainData?.slug) : null}>
                  {mainData ? langToggle(mainData?.title_ua, mainData?.title_ru, mainData?.title_en) : null}
                </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ServicesInnerMain;
