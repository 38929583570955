import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';
import ButtonBook from '../../../../UI/ButtonBook';

const SectionFooter = ({ dataHours }) => {
  const langToggle = useLanguage;

  function clickHendler() {
    console.log('click services inner');
  }

  return (
    <footer className="services-inner--footer section-footer">
      <div className="section-footer__left">
        <p className="work-hours">
          {langToggle('Працюємо:', 'Работаем:', 'We work:')}
          <span className="hours">
            {langToggle(
              dataHours?.title_ua,
              dataHours?.title_ru,
              dataHours?.title_en
            )}
          </span>
        </p>
      </div>
      <div className="section-footer__right">
        <ButtonBook
          title={langToggle(
            'Записатись на консультацію',
            'Записаться на консультацию',
            'Book a Consultation'
          )}
          clickHendler={clickHendler}
        />
      </div>
    </footer>
  );
};

export default SectionFooter;
