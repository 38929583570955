import React from 'react';
import SectionContent from './SectionContent/SectionContent';
import SectionFooter from './SectionFooter/SectionFooter';
import SectionHeader from './SectionHeader/SectionHeader';

const ServicesInnerType = ({ dataType, dataPrice }) => {
  return (
      <section className="services-inner--type">
        <div className="container-fluid">
            <SectionHeader dataTitle={dataType?.services_title} />
          <SectionContent dataItems={dataPrice} />
          <SectionFooter dataHours={dataType?.opening_hours} />
        </div>
      </section>
  );
};

export default ServicesInnerType;
