import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const SectionContent = ({ dataItems }) => {
  const langToggle = useLanguage;
  return (
    <div className="services-inner--content">
      {(dataItems || []).map((item, index) => {
        const count = index + 1;
        return (
          <div className="type-item" key={index}>
            <span className="type-item--count">
              {count <= 9 ? '0' + count + ' /' : count + ' /'}
            </span>
            <h3 className="type-item--title">
              {langToggle(item?.title_ua, item?.title_ru, item?.title_en)}
            </h3>
            <span className="type-item--price">
              {langToggle(item?.price, item?.price, item?.price_en)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default SectionContent;
