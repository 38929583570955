import React from 'react';
// import ItemButtons from './ItemButtons/ItemButtons';
import ItemContent from './ItemContent/ItemContent';
import ItemPreview from './ItemPreview/ItemPreview';

const ServicesInnerInfo = ({ descInfo, procedure }) => {
  const [activeItemID] = React.useState(0);
  // function clickHendler() {
  //   console.log('book services-inner info');
  // }

  return (
    <div className="services-inner--info">
      <div className="container-fluid">
      <div className="info-item">
              <ItemPreview descInfo={descInfo} />
              <ItemContent activeItemID={activeItemID} procedure={procedure} />
              {/* <ItemButtons
                clickHendler={clickHendler}
                activeItemID={activeItemID}
                setActiveItemID={setActiveItemID}
              /> */}
            </div>
        {/* {(dataInfo || []).map((item, index) => {
          return (
            <div className="info-item" key={index} id={item.id}>
              <ItemPreview item={item} descInfo={descInfo} />
              <ItemContent item={item} activeItemID={activeItemID} procedure={procedure} />
              <ItemButtons
                clickHendler={clickHendler}
                item={item}
                activeItemID={activeItemID}
                setActiveItemID={setActiveItemID}
              />
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default ServicesInnerInfo;
