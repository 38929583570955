import React from 'react';
import ImageCarousel from '../../../UI/ImageCarousel/ImageCarousel';

const ServicesInnerGallery = ({dataGallery}) => {
  return (
    <section className="services-inner--gallery">
      <ImageCarousel dataGallery={dataGallery}/>
    </section>
  );
};

export default ServicesInnerGallery;
