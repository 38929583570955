import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const SectionHeader = ({ dataTitle }) => {
  const langToggle = useLanguage;
  return (
    <header className="services-inner--header">
      <h1 className="section-description">
        {langToggle(dataTitle?.title_ua, dataTitle?.title_ru, dataTitle?.title_en)}
      </h1>
    </header>
  );
};

export default SectionHeader;
